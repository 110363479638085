import React from "react"
import styled from "styled-components"

import { ProductArticleExtraContent } from "../../../components"

import { PRODUCT_COLOR } from "."

const Article = (): JSX.Element => {
  return (
    <Container>
      <ProductArticleExtraContent
        color={PRODUCT_COLOR}
        title="Group Funeral Benefit"
        subTitle="Discover the Workerslife Group Funeral Benefit, offering custom group funeral coverage designed for associations, churches, burial societies, and social clubs. Get covered for up to R25,000 for the main member, spouse, and up to 6 children under the age of 26 years old."
        topic="Customisable Cover for Your Community"
      >
        <p>
          Funeral expenses can be a significant financial burden, especially
          when they affect a community or association. Workerslife understands
          the importance of providing accessible funeral coverage for groups.
          That&apos;s why we offer the Group Funeral Benefit, designed to
          provide custom group funeral coverage for associations, churches,
          burial societies, and social clubs. With this product, you can get
          covered for up to R25,000 for the main member, spouse, and up to 6
          children under the age of 26 years old. It&apos;s a practical solution
          for ensuring dignified burials within your community.
        </p>
      </ProductArticleExtraContent>
    </Container>
  )
}

export default Article

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
`
