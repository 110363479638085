import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const CustomizableBenefits = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Customisable Group Funeral Cover"
      color={PRODUCT_COLOR}
      className="slider"
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Cover for Associations",
          label:
            "The Group Funeral Benefit is tailor-made for associations, including government and private sector groups, churches, burial societies, and social clubs. We understand the unique needs of these groups and provide customisable coverage.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Flexible Cover Options",
          label:
            "This product is designed to cater to groups of varying sizes, from 10 to 200 principle members. Larger groups with more than 200 members can request a specialized quotation to suit their specific needs. This flexibility ensures that the cover aligns with the group's requirements.",
        },
      ]}
    />
  )
}

export default CustomizableBenefits
