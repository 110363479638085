import React from "react"
import styled from "styled-components"

import {
  AdditionalBenefits,
  Article,
  Benefits,
  CustomizableBenefits,
  SEO,
  Conclusion,
} from "../../sections/products/group-funeral"

const LifeAndLiveSecureBenefit: React.FC = () => (
  <PageWrapper>
    <SEO />
    <Article />
    <CustomizableBenefits />
    <Benefits />
    <AdditionalBenefits />
    <Conclusion />
  </PageWrapper>
)

export default LifeAndLiveSecureBenefit

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`
