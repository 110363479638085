import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"

import { ProductTilesWrapper } from "../../../components"

import { CoinPileIcon } from "../../../assets/svg"

import { PRODUCT_COLOR } from "."
import ColorTilesRevamped from "../../../components/ColorTilesRevamped"

export const BENEFITS = [
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Vehicle Assist Benefit",
        text: "You can opt for additional benefits such as vehicle assist, which provides support during challenging transportation situations.",
      },
      {
        label: "Airtime Benefit",
        text: "In times of need, communication is crucial. That's why we offer an airtime benefit to ensure that you can stay connected when it matters the most.",
      },
    ],
  },
  {
    opacity: "0.3",
    benefits: [
      {
        label: "Repatriation Benefit",
        text: "Our product includes a repatriation benefit, ensuring that loved ones can be transported for a dignified burial, even if they're far away.",
      },
      {
        label: "Premium Waiver",
        text: "In the unfortunate event of the death of a principal member, our product offers a premium waiver, providing financial relief to the group during this challenging period.",
      },
    ],
  },
  {
    opacity: "0.6",
    benefits: [
      {
        label: "Optional Extended Family Benefit",
        text: "You can also opt for an extended family benefit, ensuring that your coverage extends to include extended family members.",
      },
    ],
  },
]

const query = graphql`
  {
    bgImage: file(
      relativePath: {
        eq: "products/comprehensive-legal-insurance/additional-benefits.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AdditionalBenefits = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <Container>
      <h2>Additional benefits available on the Legal App...</h2>
      <ProductTilesWrapper color={PRODUCT_COLOR} tileType="icon">
        <BackgroundImage fluid={imageData} preserveStackingContext>
          <ColorTilesRevamped
            tiles={BENEFITS.map(benefit => ({
              tileType: "text-with-icon",
              color: PRODUCT_COLOR,
              icon: <CoinPileIcon />,
              ...benefit,
            }))}
          />
        </BackgroundImage>
      </ProductTilesWrapper>
    </Container>
  )
}

export default AdditionalBenefits

const Container = styled.div`
  h2 {
    margin-bottom: 78px;
    text-align: center;
  }
`
const Disclaimer = styled.p`
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06em;
  margin-top: 25px;

  a {
    font-family: "SofiaPro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`
