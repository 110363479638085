import React from "react"
import styled from "styled-components"

import { PRODUCT_COLOR } from "."
import { CoverButton } from "../../../elements"

const Conclusion = (): JSX.Element => {
  return (
    <Container>
      <div className="sectionHeader">
        <h2>Secure Dignified Farewells for Your Group</h2>
      </div>

      <p>
        The Workerslife Group Funeral Benefit is designed to provide accessible
        funeral cover for associations, churches, burial societies, and social
        clubs. With customisable coverage options and a maximum cover of up to
        R25,000, this product ensures that the financial burden of a funeral is
        alleviated. When you choose Workerslife, you&apos;re choosing to secure
        dignified farewells for your community.
      </p>
      <div className="buttonContainer">
        <CoverButton
          filled
          buttonColor={PRODUCT_COLOR}
          textColor="#fff"
          label="Get Covered"
        />
      </div>
      <p className="styledFooter">
        Invest in the Group Funeral Benefit and provide your community with the
        peace of mind they deserve during difficult times. Ensure that dignified
        burials are accessible to your group with this practical solution.
      </p>
    </Container>
  )
}

export default Conclusion

const Container = styled.div`
  p {
    padding: 0 30px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
  .styledFooter {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
  .coverButton {
    margin: 60px auto;
  }
  .buttonContainer {
    padding: 0 20px;
  }
  .sectionHeader {
    margin: 60px auto 40px auto;
    max-width: 970px;
    padding: 0 30px;

    h2 {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      margin: 40px auto 90px auto;

      p {
        font-size: 26px;
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
      margin: 100px auto 40px auto;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoints.lg}) {
      margin: 0 auto 90px auto;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    .buttonContainer {
      padding: 0;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    p,
    .sectionHeader {
      padding: 0 60px;
    }
  }
`
