import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import { PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/comprehensive-legal-insurance/"

const Benefits = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Benefits of Group Funeral Product"
      color={PRODUCT_COLOR}
      className="slider"
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Maximum Cover of Up to R25,000",
          label:
            "With the Group Funeral Benefit, you can get covered for up to R25,000 for the main member, spouse, and up to 6 children under the age of 26 years old. This comprehensive benefit ensures that the financial burden of a funeral is alleviated.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Cash Payouts",
          label:
            "In the unfortunate event of the death of a Principal Member, Spouse, Eligible Children, or Extended Family, this product pays out a cash sum. This payout can provide crucial financial support during a challenging time.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Unique Package with Great Benefits",
          label:
            "This plan offers a unique package that incorporates a range of benefits designed to support the group and ensure dignified farewells.",
        },
      ]}
      reverse={true}
    />
  )
}

export default Benefits
