import React from "react"
import { SEO } from "../../../components"
import { ArticleJsonLd } from "../GoogleSEO"

const PRODUCT_NAME = "Group Funeral Benefit"
const DESCRIPTION =
  "Secure dignified group funeral coverage for members with Workerslife's Group Funeral Benefit. Ease financial burdens during difficult times."

const ProductSEO = (): JSX.Element => (
  <>
    <SEO title={PRODUCT_NAME} description={DESCRIPTION} />
    <ArticleJsonLd
      headline={PRODUCT_NAME}
      description={DESCRIPTION}
      image="https://www.workerslife.co.za/static/78e9ec61ae1fcf1580a9a52471ae5b90/a3f22/group-funeral-header.webp"
      url="https://www.workerslife.co.za/products/group-funeral-benefit/"
    />
  </>
)

export default ProductSEO
