import {
  ArticleJsonLd as ArticleBase,
  ProductJsonLd as ProductBase,
} from "gatsby-plugin-next-seo"
import React from "react"

interface ProductProps {
  name: string
  description: string
  lowPrice: number
}

export const ProductJsonLd: React.FC<ProductProps> = ({
  name,
  description,
  lowPrice,
}) => (
  <ProductBase
    name={name}
    description={description}
    offersType="AggregateOffer"
    offers={{
      lowPrice: lowPrice, // exists on AggregateOffer and passes Google validator
      price: "",
      priceCurrency: "ZAR",
    }}
  />
)

interface ArticleProps {
  headline: string
  description: string
  image: string
  url: string
}

export const ArticleJsonLd: React.FC<ArticleProps> = ({
  headline,
  description,
  image,
  url,
}) => (
  <ArticleBase
    headline={headline}
    description={description}
    images={[image]}
    url={url}
    authorName="Workerslife"
    authorType="Organization"
    publisherName=""
    publisherLogo=""
    datePublished=""
  />
)
